import { graphql } from 'gatsby';
import React from 'react';
import { PaginatedItems } from '../components/Paginate';
import '../../src/App.css'


const Index = ({ data }) => {
  return (
    <>
      <div className="cards-width" style={{
          marginBottom: "-2px"
         }}>
      </div>
      <div id="container">
      <PaginatedItems
        itemsPerPage={15}
        items={data.allMdx.nodes}
      />
    </div>
    </>
  );
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date(formatString: "YYYY MMMM Do")
          cover {
            publicURL
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;

export default Index;